.chatMain{
  height: 91vh;
}
.fullwidth{
  height: 98vh;
}
.messageCard {
  height: 100%;
}

.chat-contacts {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  background-color: #f8f9fa;
  /* border: 3px solid red; */
}

.chat-contact-link {
  text-decoration: none;
  color: inherit;
}

.chat-contact {
  border-bottom: 1px solid #e9ecef;
  padding: 10px 0;
  transition: background-color 0.3s;
}

.chat-contact:hover {
  background-color: #e9ecef;
}

.chat-contact-initial {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #686869;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.chat-contact-subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-contact-date {
  font-size: 0.8rem;
}

.chat-contact-to {
  font-size: 0.9rem;
}


/* Chat Window */

.chat-content {
  overflow-y: auto;
  height: 100%;
}

.chat-content::-webkit-scrollbar {
  display: none;
}

.chatBox .messageCard {
  border-radius: 4px;
}

.messageCard-header {
  background-color: #0044cd !;
}

.header-title {
  height: 50px;
}

.messageCard-header-title i {
  font-size: 10px;
  color: #32e4cd;
  margin-right: 6px;
}

.chat-content small {
  margin: 0;
}

.chat-content p {
  background: #ecf1f8;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 0;
}

.my-content .media-content {
  width: 80%;
}

.my-content .message {
  float: right;
  background: #683db8;
  color: #FFF;
  text-align: right;
  padding: 10px;
  margin-bottom: 4px;
  font-size: 13px;
}

.my-content .chat-content small {
  float: right;
}

.my-content small {
  display: block;
  float: right;
  width: 100%;
  text-align: right;
}

.chat-textarea {
  font-size: 14px;
  padding: 8px;
  height: 40px;
  width: 90%;
  border: none;
  overflow: auto;
  outline: none;
  border-radius: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.chat-message-group {}

.chat-message-group .chat-thumb {
  float: left;
  width: 20%;
}

.chat-message-group .chat-messages {
  float: left;
  width: 80%;
  margin-bottom: 20px;
}

.chat-message-group .message {
  float: left;
  padding: 10px;
  background: #ecf1f8;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 3px;
}

.chat-messages .from {
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 11px;
}

.writer-user .chat-messages {
  float: right;
  width: 100%;
}

.writer-user .chat-messages .message {
  float: right;
  background: #683db8;
  color: #FFF;
}

.writer-user .chat-messages .from {
  float: left;
  display: block;
  width: 100%;
  text-align: right;
}

.chat-message-group .typing {
  float: left;
}

.chatBox .messageCard-header-icon i {
  color: #FFF;
  font-size: 13px;
}

/* CSSS */
.outside-box {
  height: 100px;
  background: #F8C;
  width: 200px;
  margin: 20px;
  overflow: auto;
}

.outside-box .content-insider {
  height: 300px;
  background: #C9C;
}

/* */
.messageCard-header-title img {
  border-radius: 40px;
}

.messageCard-footer {
  display: flex;
  background-color: white;
  position: sticky;
  bottom: 0px;
  border: 1px solid rgb(176, 176, 176);
  border-radius: 20px;

}