.square-image{
    width: 100%;
}


.image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    overflow: hidden;
    background-color: rgb(42, 42, 42);
    border-radius: 10px;
    border: 3px solid rgb(132, 132, 132);
}

.image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the square area */
    object-position: center; /* Center the image within the square */
}
