.colContainer {
  display: flex;
  justify-content: center;
}

.iconContainer {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashIcons {
  font-size: 30px;
}

.IconText {
  display: block;
  text-align: center;
  margin-top: 5px;
  font-size: 0.8rem;
  color: #333;
}

.three h2 {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  position: relative;
}

