.dayTableContainer {
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    padding: 5px 0;
    background-color: white;
    z-index: 1;
  }
  
  .dayTableContainer>.days {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    padding: 1px;
  
  }
  
  .dayTableContainer>.selectedDay {
    /* border: 1px solid black; */
    color: white;
    margin-top: 4px;
    background-color:#5852ee;
    font-family: 'Poppins';
    border-radius: 50%;
  }
  .subContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .leftNumberContainer {
  
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .leftNumberContainer>.leftNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 2px;
    color: white;
    background-color: #5852ee;
    border-radius: 50%;
  }
  
  .rightContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    min-height: 70px;
    padding: 4px;
    background-color:#fbf8ff;
    border-radius: 5px;
    border-left: 5px solid #5852ee;
  
    
  }
  .lectureData{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fbf8ff;
  }
   .lectureData .time , .teacherName{
    color: #111;
  }
  .lectureData .lecture{
    font-weight: 600;
    padding: 5px 0;
  }
  
  .meetingID{
    font-size: 25px;
    padding: 5px;
  }