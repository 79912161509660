.colContainer {
    display: flex;
    justify-content: center;
   
    /* margin: 10px; */
}

.iconContainer {
    width: 55px; 
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashIcons {
    font-size: 30px; 
    
   /* color: #000;*/ /* Icon color */
}

.IconText {
    display: block;
    text-align: center;
    margin-top: 5px;
    font-size: 0.8rem; /* Adjust text size as needed */
    color: #333; /* Text color */
}


/* === HEADING STYLE #3 === */
.three h2 {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.5em;
    position: relative;
  }


  /* Glow Effect */
  