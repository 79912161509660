/* styles.css or App.css */
.folder-item {
    display: flex;
    align-items: center;
  }
  
  .folder-item .mr-2 {
    margin-right: 8px;
  }
  .custom-left-margin {
    margin-left: 15px; 
  }


  /* LIST OF GRID IMAGES */
  /* Image grid container */
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid */
  gap: 16px;
  padding: 20px;
}

/* Image item styling */
.image-item {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

/* Image styling */
.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}


/* Modal styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  cursor: pointer;
}
