.LogoContainer {

    text-align: center;
    margin-top: 10px;
}

.schoolLogo {
    width: 95%;
    max-width: 80px;
    height: 80px;
    border: 4px solid rgb(247, 230, 198);
    border-radius: 50%;
    padding: 1px;
}
