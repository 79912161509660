.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 55px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: #fff;
}
.mobile-bottom-nav__item {
  flex-grow: 1;
  text-align: center;
  /* font-size: 1.8vh; */
  display: flex;
  flex-direction: column;
  justify-content:end;
  margin-bottom: 1px;
  text-overflow:ellipsis
}
.mobile-bottom-nav_icon{
  font-size: 25px;
}
.mobile-bottom-nav__item--active {
  color: red;
}
.mobile-bottom-nav__item-content {
  display: flex;
  flex-direction: column;
}



@media print { 
    .no-printme  { display: none;}
    .printme  { display: block;}
}