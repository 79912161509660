.dayTableContainer {
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
  background-color: white;
  z-index: 1;
}

.dayTableContainer>.days {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 1px;

}

.dayTableContainer>.selectedDay {
  /* border: 1px solid black; */
  color: white;
  background-color: #0D6EFD;
  border-radius: 50%;
}
.subContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.leftNumberContainer {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.leftNumberContainer>.leftNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 2px;
  color: white;
  background-color: #9DB2BF;
  border-radius: 50%;
}

.rightContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  min-height: 70px;
  padding: 4px;
  background-color: #F0F5F9;
  border-radius: 5px;
  border-left: 5px solid #526D82;

  
}
.lectureData{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
 .lectureData .time , .teacherName{
  color: gray;
}
.lectureData .lecture{
  font-weight: 600;
  padding: 5px 0;
}

.meetingID{
  font-size: 25px;
  padding: 5px;
}