.notification-page {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Poppins";
    margin: 0;
    padding: 0;
}
.notification_search {
    position: fixed;
    width: 100%;
    z-index: 3;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 30px;
    }
.metadata {
    display: flex;
    justify-content: space-between;
    padding: 0;
    position: relative;
    bottom: -4px;
}

.metadata .time {
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
    display: inline-block;
}


.announcement-card {
    background-color: #96CEB4 !important;
    max-width: 700px;
}

.studentSpecific-card {
    background-color: #FEF3E2 !important;
    max-width: 700px;
}

.Homework-card {
    background-color: #CADABF!important;
    max-width: 700px;
}