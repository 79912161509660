.upper-div {
    width: 100%;
    height: 30vh;
    background: rgb(0, 212, 255);
    background: linear-gradient(90deg, rgba(0, 212, 255, 1) 0%, rgba(9, 9, 121, 1) 100%);
    padding: 8px;
    color: white;
    position: relative;
}

.info-section {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 10px;
    padding: 0 10px;
}

.balance-info,
.actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.balance-info {
    margin-bottom: 30px;
}

.actions>button {
    width: 80px;
    background: rgba(0, 0, 0, 0.473);
}

.left-section>p,
.right-section>p {
    font-size: 18px;
    margin: 4px 0;
    color: rgba(4, 0, 0, 0.541);
}

.left-section>h2 {
    font-size: 40px;
}

.right-section>h6 {
    font-size: 20px;
}



.record-card {
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px;
    margin: 15px 10px 0px 10px;
    border: 1px solid rgba(0, 0, 0, 0.094);
}

.record-card p {
    margin: 2px 0px;
    font-size: 16px;
}

.record-card span {
    color: rgba(0, 0, 0, 0.521);
    font-weight: 500;
    font-size: 12px;
}